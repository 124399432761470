import { formatURIComponent } from './utils'

export default (instance:string) => {

    const makeUrl = (path:string) => {
        return `/browser2?file_path=${formatURIComponent(path, instance)}&menu_key=opening&instance=${instance}`
    }

    return [{
        pageLayout: 'tabs_small',
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '重新定义国宾馆',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        description: `华美顾问凭借服务于1000+新建酒店项⽬所积累的丰富经验和海量数据，为
酒店业主提供酒店建设全过程咨询顾问服务。`,
        icons: [
            {
                name: '全球案例',
                img: '/icons/案例库.svg',
                background: 'black',
                url: makeUrl('/内容文件/四库赋能-酒店建设/重新定义国宾馆/全球案例/全球案例（重新定义国宾馆）.xlsx'),
                path: "四库赋能-酒店建设/重新定义国宾馆/全球案例"
            },
            {
                name: '预警问题',
                img: '/icons/工具库.svg',
                background: 'black',
                url: makeUrl('/内容文件/四库赋能-酒店建设/重新定义国宾馆/预警问题/预警问题（重新定义国宾馆）.pdf'),
                path: "四库赋能-酒店建设/重新定义国宾馆/预警问题"
            },
            {
                name: '智能工具',
                img: '/icons/工具库.svg',
                background: 'black',
                url: makeUrl('/内容文件/四库赋能-酒店建设/重新定义国宾馆/智能工具/智能工具.xlsx'),
                path: "四库赋能-酒店建设/重新定义国宾馆/智能工具"
            },
            {
                name: '优选资源',
                img: '/icons/工具库.svg',
                background: 'black',
                url: makeUrl('/内容文件/四库赋能-酒店建设/重新定义国宾馆/优选资源'),
                path: "四库赋能-酒店建设/重新定义国宾馆/优选资源"
            },
        ]
    }]
}