import { makeRecommends } from "./utils"
import { formatURIComponent } from "./utils"

export default (instance:string) => {

    const makeUrl = (path:string) => {
        return `/browser2?file_path=${formatURIComponent(path, instance)}&menu_key=industryWatch&instance=${instance}`
    }

    return [{
        slogan: '华美服务 = 咨询服务 + 华美云（四库赋能）',
        pageTitle: '行业观察',
        description: '',
        pageTitleIcon: '',
        background_img: '',
        icons: [
            {
                name: '行业数据',
                img: '/icons/大数据.svg',
                background: 'black',
                url: makeUrl('行业观察/行业数据'),
                path: "行业观察/行业数据"
            },
            // ...makeRecommends('行业观察/行业数据'),
            {
                name: '在建待建',
                img: '/icons/趋势分析.svg',
                background: 'black',
                url: makeUrl('行业观察/在建待建'),
                path: "行业观察/在建待建"
            },
            // ...makeRecommends('行业观察/在建待建'),
            {
                name: '华美研报',
                img: '/icons/新开酒店.svg',
                background: 'black',
                url: makeUrl('行业观察/华美研报'),
                path: "行业观察/华美研报"
            },
            // ...makeRecommends('行业观察/华美研报'),
            {
                name: '机构研报',
                img: '/icons/时间线.svg',
                background: 'black',
                url: makeUrl('行业观察/机构研报'),
                path: "行业观察/机构研报"
            },
            // ...makeRecommends('行业观察/机构研报'),
            {
                name: '酒店图书馆',
                img: '/icons/对比分析.svg',
                background: 'black',
                url: makeUrl('行业观察/酒店图书馆'),
                path: "行业观察/酒店图书馆"
            },
            // ...makeRecommends('行业观察/酒店图书馆')
        ]
    }]
}