import { formatURIComponent } from './utils'

export default (instance:string) => {

    const makeUrl = (path:string) => {
        return `/browser2?file_path=${formatURIComponent(path, instance)}&menu_key=city&instance=${instance}`
    }
    
    return [{
        pageLayout: 'tabs_small',
        slogan: '五星档次酒店发展',
        pageTitle: '评估与展望 - '+instance,
        sectionCategory: "city",
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '时间线',
                img: '/icons/时间线.svg',
                background: 'black',
                url: makeUrl('/内容文件/评估展望/城市/时间线/%s.png'),
                path: "评估展望/城市/时间线"
            },
            {
                name: '酒店清单',
                img: '/icons/新开酒店.svg',
                background: 'black',
                url: makeUrl('/内容文件/评估展望/城市/酒店清单/%s.json'),
                path: "评估展望/城市/酒店清单"
            },
            {
                name: '在建待建',
                img: '/icons/在建待建.svg',
                background: 'black',
                url: makeUrl('/内容文件/评估展望/城市/在建待建/%s.json'),
                path: "评估展望/城市/在建待建"
            },
            // {
            //     name: '大数据',
            //     img: '/icons/大数据.svg',
            //     background: 'black',
            //     url: makeUrl('城市大数据'),
            //     path: "评估展望/城市/大数据"
            // },
            {
                name: '趋势分析',
                img: '/icons/趋势分析.svg',
                background: 'black',
                url: makeUrl('/内容文件/评估展望/城市/趋势分析/%s.pdf'),
                path: "评估展望/城市/趋势分析"
            },
            {
                name: '对比分析',
                tabStyle:"color:red",
                img: '/icons/对比分析.svg',
                background: 'black',
                url: makeUrl('/内容文件/评估展望/城市/对比分析/%s.pdf'),
                path: "评估展望/城市/对比分析"
            },
        ]
    }]
}