import {message} from 'ant-design-vue'

export const lsOss = async (oss_key: string) => {
    console.log("lsOss", oss_key)
    try{
        const res = await fetch2.get('/ali-oss/ls?key=' + oss_key, {})
        if (res.status == 200){
            if(res.data){
                return res.data
            }else{
                console.log('lsOss 文件查询失败 1' + res.data.message)
                return ''
            }
        }else{
            console.log('lsOss 文件查询失败 2' + res.status)
            return ''
        }
    }catch(e){
        console.log('lsOss 文件查询失败 3' + e)
        return ''
    }
}