export const getSearchOptionCities = async () => {
    const res = await fetch('/selectOptions/cities.json')
    const data = await res.json()
    return data
}

export const getSearchOptionBrands = async () => {
    const res = await fetch('/selectOptions/brands.json')
    const data = await res.json()
    return data
}

export const getSearchOptionGroups = async () => {
    const res = await fetch('/selectOptions/groups.json')
    const data = await res.json()
    return data
}

export const getSearchOptionHotels = async () => {
    const res = await fetch('/selectOptions/hotels.json')
    const data = await res.json()
    return data
}

