import { default as getTargetMenuYVdkIMaDapMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser/getTargetMenu.ts?macro=true";
import { default as indextv3OV33dT9Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser/index.vue?macro=true";
import { default as getTargetMenuyyJwJWdKYyMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser2/getTargetMenu.ts?macro=true";
import { default as index8mknj4EEC0Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser2/index.vue?macro=true";
import { default as processMenuUft8XYoETyMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser2/processMenu.ts?macro=true";
import { default as signatures3zI6uUVD0JMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser2/signatures.ts?macro=true";
import { default as index582VG4ClmZMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/collabPage/index.vue?macro=true";
import { default as _91_filePath_930iEtroDi77Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/[_filePath].vue?macro=true";
import { default as displayProjectsW6O96zadKRMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/displayProjects.ts?macro=true";
import { default as getAdFileshvLuUjOWD2Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getAdFiles.ts?macro=true";
import { default as getCategory1Options2WFTBPeyW5Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getCategory1Options.ts?macro=true";
import { default as getPageContentjyFik7DjpPMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getPageContent.ts?macro=true";
import { default as getPageContentOverridegdjDlBTOKMMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getPageContentOverride.ts?macro=true";
import { default as getTargetMenuk6Vs7hvAu5Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getTargetMenu.ts?macro=true";
import { default as huameiAnnualsCagTZ4G5MGMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/huameiAnnuals.ts?macro=true";
import { default as processLeftMenu3rWX6AybrzMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/processLeftMenu.ts?macro=true";
import { default as brandsOC5pYmHS87Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/utils/brands.ts?macro=true";
import { default as cities3PtWIyRhRgMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/utils/cities.ts?macro=true";
import { default as hotel_45viewerAEosHo7RceMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/hotel-viewer.vue?macro=true";
import { default as getDatawmsqXkBiv5Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/instancePicker/getData.ts?macro=true";
import { default as handleInstance5HPVSVZkfnMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/instancePicker/handleInstance.ts?macro=true";
import { default as indexiDp8GTjI7jMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/instancePicker/index.vue?macro=true";
import { default as loginBIQkEeQYpYMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/login.vue?macro=true";
import { default as afterLogin8VXc9uHXVQMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/loginUtils/afterLogin.ts?macro=true";
import { default as getAuthorizationsBvLwA0T6qWMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/loginUtils/afterLoginUtils/getAuthorizations.ts?macro=true";
import { default as sendSMSwSxCT0QuHBMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/loginUtils/sendSMS.ts?macro=true";
import { default as SMSLoginGb5uNylBBGMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/loginUtils/SMSLogin.ts?macro=true";
import { default as getAdFiles436Ogwp2vVMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/project-list/getAdFiles.ts?macro=true";
import { default as indexByoDZn8mCBMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/project-list/index.vue?macro=true";
import { default as report_45viewerdim7pBUmn1Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/report-viewer.vue?macro=true";
import { default as brand5ovYSb9TsmMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/brand.vue?macro=true";
import { default as citykbQDeQQGyGMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/city.vue?macro=true";
import { default as cityWallDatasu1QiUAi8UMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/cityWallData.ts?macro=true";
import { default as groupuKY6AEWbO7Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/group.vue?macro=true";
import { default as hotelPY2LgmpwiPMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/hotel.vue?macro=true";
import { default as hotelWallDataLe4IiPvMS1Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/hotelWallData.ts?macro=true";
import { default as managementGroupWallDataPSQZzjNxjDMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/managementGroupWallData.ts?macro=true";
import { default as _91section_93Xk0KZcqwxdMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/[section].vue?macro=true";
import { default as checkAuthDRQeRvqMdNMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/checkAuth.ts?macro=true";
import { default as onClickContentNodey53Zimr6HMMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/onClickContentNode.ts?macro=true";
import { default as index2vMapyHD3XMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/selectOptions/index.ts?macro=true";
import { default as asset9XeKkll9hgMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/asset.ts?macro=true";
import { default as assetValuer3kSHZJDxmMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/assetValue.ts?macro=true";
import { default as brandn1JVwuaAoUMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/brand.ts?macro=true";
import { default as brandNegotiateSJ0oSiIZ8CMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/brandNegotiate.ts?macro=true";
import { default as budgetcQgbNH5sJiMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/budget.ts?macro=true";
import { default as buildSnvP5i46HzMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/build.ts?macro=true";
import { default as cityYCBzozgrzBMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/city.ts?macro=true";
import { default as cityCentralActivityZonepSla2S6fbKMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/cityCentralActivityZone.ts?macro=true";
import { default as cityStrategyoF4RCMMwQlMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/cityStrategy.ts?macro=true";
import { default as designTx0Kh53qLNMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/design.ts?macro=true";
import { default as empowermentUFkEn5QyZvMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/empowerment.ts?macro=true";
import { default as evaluationProspect85DASyX29KMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/evaluationProspect.ts?macro=true";
import { default as feasibilitycbSUUr76oPMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/feasibility.ts?macro=true";
import { default as groupQDmjMNuotLMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/group.ts?macro=true";
import { default as groupStrategypxYFIKDhhcMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/groupStrategy.ts?macro=true";
import { default as happyIndustryXyRaGW3vnvMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/happyIndustry.ts?macro=true";
import { default as homeGroupC5mQyDCakfMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homeGroup.ts?macro=true";
import { default as homeHotelc8j1qu58qnMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homeHotel.ts?macro=true";
import { default as homeHuamei9hiivY7sNqMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homeHuamei.ts?macro=true";
import { default as homeOwnerYedOLACFytMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homeOwner.ts?macro=true";
import { default as homePartnersfP39vaPCclMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homePartners.ts?macro=true";
import { default as hotelOydZNjLB6RMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/hotel.ts?macro=true";
import { default as hotelGroupTourismDestination5fFqVs2ZJ2Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/hotelGroupTourismDestination.ts?macro=true";
import { default as humanResourcezxyzOQa9f3Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/humanResource.ts?macro=true";
import { default as indexcS2hq17QwAMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/index.ts?macro=true";
import { default as industryWatchvxCA7i8Q20Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/industryWatch.ts?macro=true";
import { default as mediationxOleFXU5OfMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/mediation.ts?macro=true";
import { default as nationalHotelXXpWeUDTvKMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/nationalHotel.ts?macro=true";
import { default as openingyt85EyDQTJMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/opening.ts?macro=true";
import { default as operationMfpMBoEZlqMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/operation.ts?macro=true";
import { default as owner8RbSL6bDKHMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/owner.ts?macro=true";
import { default as performanceIjZkAOBkfuMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/performance.ts?macro=true";
import { default as productBrandStrategyslo2sUsOULMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/productBrandStrategy.ts?macro=true";
import { default as purchasePWmYsFl9QSMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/purchase.ts?macro=true";
import { default as resourcesi97bYODWlgMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/resources.ts?macro=true";
import { default as savingsqiP3brBElwMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/savings.ts?macro=true";
import { default as specialTownloI44D33emMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/specialTown.ts?macro=true";
import { default as utilsWxXtPbdPrkMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/utils.ts?macro=true";
import { default as _91_filePath_937Gx8uAUsvbMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/[_filePath].vue?macro=true";
import { default as displayProjectslMdifUvlPSMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/displayProjects.ts?macro=true";
import { default as getAdFilesIRK0XrYorDMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/getAdFiles.ts?macro=true";
import { default as getCategory1OptionsdeyExqkpl5Meta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/getCategory1Options.ts?macro=true";
import { default as getPageContentiY5kyafR1bMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/getPageContent.ts?macro=true";
import { default as getPageContentOverridei5yz5N3DblMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/getPageContentOverride.ts?macro=true";
import { default as huameiAnnualspwv2HSaQpGMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/huameiAnnuals.ts?macro=true";
import { default as processLeftMenu2nzk4BAGiuMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/processLeftMenu.ts?macro=true";
import { default as brandsY3NYGoxlAzMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/utils/brands.ts?macro=true";
import { default as citiesvAE3DBHTygMeta } from "/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/utils/cities.ts?macro=true";
export default [
  {
    name: getTargetMenuYVdkIMaDapMeta?.name ?? "browser-getTargetMenu",
    path: getTargetMenuYVdkIMaDapMeta?.path ?? "/browser/getTargetMenu",
    meta: getTargetMenuYVdkIMaDapMeta || {},
    alias: getTargetMenuYVdkIMaDapMeta?.alias || [],
    redirect: getTargetMenuYVdkIMaDapMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser/getTargetMenu.ts").then(m => m.default || m)
  },
  {
    name: indextv3OV33dT9Meta?.name ?? "browser",
    path: indextv3OV33dT9Meta?.path ?? "/browser",
    meta: indextv3OV33dT9Meta || {},
    alias: indextv3OV33dT9Meta?.alias || [],
    redirect: indextv3OV33dT9Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser/index.vue").then(m => m.default || m)
  },
  {
    name: getTargetMenuyyJwJWdKYyMeta?.name ?? "browser2-getTargetMenu",
    path: getTargetMenuyyJwJWdKYyMeta?.path ?? "/browser2/getTargetMenu",
    meta: getTargetMenuyyJwJWdKYyMeta || {},
    alias: getTargetMenuyyJwJWdKYyMeta?.alias || [],
    redirect: getTargetMenuyyJwJWdKYyMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser2/getTargetMenu.ts").then(m => m.default || m)
  },
  {
    name: index8mknj4EEC0Meta?.name ?? "browser2",
    path: index8mknj4EEC0Meta?.path ?? "/browser2",
    meta: index8mknj4EEC0Meta || {},
    alias: index8mknj4EEC0Meta?.alias || [],
    redirect: index8mknj4EEC0Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser2/index.vue").then(m => m.default || m)
  },
  {
    name: processMenuUft8XYoETyMeta?.name ?? "browser2-processMenu",
    path: processMenuUft8XYoETyMeta?.path ?? "/browser2/processMenu",
    meta: processMenuUft8XYoETyMeta || {},
    alias: processMenuUft8XYoETyMeta?.alias || [],
    redirect: processMenuUft8XYoETyMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser2/processMenu.ts").then(m => m.default || m)
  },
  {
    name: signatures3zI6uUVD0JMeta?.name ?? "browser2-signatures",
    path: signatures3zI6uUVD0JMeta?.path ?? "/browser2/signatures",
    meta: signatures3zI6uUVD0JMeta || {},
    alias: signatures3zI6uUVD0JMeta?.alias || [],
    redirect: signatures3zI6uUVD0JMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/browser2/signatures.ts").then(m => m.default || m)
  },
  {
    name: index582VG4ClmZMeta?.name ?? "collabPage",
    path: index582VG4ClmZMeta?.path ?? "/collabPage",
    meta: index582VG4ClmZMeta || {},
    alias: index582VG4ClmZMeta?.alias || [],
    redirect: index582VG4ClmZMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/collabPage/index.vue").then(m => m.default || m)
  },
  {
    name: _91_filePath_930iEtroDi77Meta?.name ?? "datapage-_filePath",
    path: _91_filePath_930iEtroDi77Meta?.path ?? "/datapage/:_filePath()",
    meta: _91_filePath_930iEtroDi77Meta || {},
    alias: _91_filePath_930iEtroDi77Meta?.alias || [],
    redirect: _91_filePath_930iEtroDi77Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/[_filePath].vue").then(m => m.default || m)
  },
  {
    name: displayProjectsW6O96zadKRMeta?.name ?? "datapage-v3Utils-displayProjects",
    path: displayProjectsW6O96zadKRMeta?.path ?? "/datapage/v3Utils/displayProjects",
    meta: displayProjectsW6O96zadKRMeta || {},
    alias: displayProjectsW6O96zadKRMeta?.alias || [],
    redirect: displayProjectsW6O96zadKRMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/displayProjects.ts").then(m => m.default || m)
  },
  {
    name: getAdFileshvLuUjOWD2Meta?.name ?? "datapage-v3Utils-getAdFiles",
    path: getAdFileshvLuUjOWD2Meta?.path ?? "/datapage/v3Utils/getAdFiles",
    meta: getAdFileshvLuUjOWD2Meta || {},
    alias: getAdFileshvLuUjOWD2Meta?.alias || [],
    redirect: getAdFileshvLuUjOWD2Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getAdFiles.ts").then(m => m.default || m)
  },
  {
    name: getCategory1Options2WFTBPeyW5Meta?.name ?? "datapage-v3Utils-getCategory1Options",
    path: getCategory1Options2WFTBPeyW5Meta?.path ?? "/datapage/v3Utils/getCategory1Options",
    meta: getCategory1Options2WFTBPeyW5Meta || {},
    alias: getCategory1Options2WFTBPeyW5Meta?.alias || [],
    redirect: getCategory1Options2WFTBPeyW5Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getCategory1Options.ts").then(m => m.default || m)
  },
  {
    name: getPageContentjyFik7DjpPMeta?.name ?? "datapage-v3Utils-getPageContent",
    path: getPageContentjyFik7DjpPMeta?.path ?? "/datapage/v3Utils/getPageContent",
    meta: getPageContentjyFik7DjpPMeta || {},
    alias: getPageContentjyFik7DjpPMeta?.alias || [],
    redirect: getPageContentjyFik7DjpPMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getPageContent.ts").then(m => m.default || m)
  },
  {
    name: getPageContentOverridegdjDlBTOKMMeta?.name ?? "datapage-v3Utils-getPageContentOverride",
    path: getPageContentOverridegdjDlBTOKMMeta?.path ?? "/datapage/v3Utils/getPageContentOverride",
    meta: getPageContentOverridegdjDlBTOKMMeta || {},
    alias: getPageContentOverridegdjDlBTOKMMeta?.alias || [],
    redirect: getPageContentOverridegdjDlBTOKMMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getPageContentOverride.ts").then(m => m.default || m)
  },
  {
    name: getTargetMenuk6Vs7hvAu5Meta?.name ?? "datapage-v3Utils-getTargetMenu",
    path: getTargetMenuk6Vs7hvAu5Meta?.path ?? "/datapage/v3Utils/getTargetMenu",
    meta: getTargetMenuk6Vs7hvAu5Meta || {},
    alias: getTargetMenuk6Vs7hvAu5Meta?.alias || [],
    redirect: getTargetMenuk6Vs7hvAu5Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/getTargetMenu.ts").then(m => m.default || m)
  },
  {
    name: huameiAnnualsCagTZ4G5MGMeta?.name ?? "datapage-v3Utils-huameiAnnuals",
    path: huameiAnnualsCagTZ4G5MGMeta?.path ?? "/datapage/v3Utils/huameiAnnuals",
    meta: huameiAnnualsCagTZ4G5MGMeta || {},
    alias: huameiAnnualsCagTZ4G5MGMeta?.alias || [],
    redirect: huameiAnnualsCagTZ4G5MGMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/huameiAnnuals.ts").then(m => m.default || m)
  },
  {
    name: processLeftMenu3rWX6AybrzMeta?.name ?? "datapage-v3Utils-processLeftMenu",
    path: processLeftMenu3rWX6AybrzMeta?.path ?? "/datapage/v3Utils/processLeftMenu",
    meta: processLeftMenu3rWX6AybrzMeta || {},
    alias: processLeftMenu3rWX6AybrzMeta?.alias || [],
    redirect: processLeftMenu3rWX6AybrzMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/processLeftMenu.ts").then(m => m.default || m)
  },
  {
    name: brandsOC5pYmHS87Meta?.name ?? "datapage-v3Utils-utils-brands",
    path: brandsOC5pYmHS87Meta?.path ?? "/datapage/v3Utils/utils/brands",
    meta: brandsOC5pYmHS87Meta || {},
    alias: brandsOC5pYmHS87Meta?.alias || [],
    redirect: brandsOC5pYmHS87Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/utils/brands.ts").then(m => m.default || m)
  },
  {
    name: cities3PtWIyRhRgMeta?.name ?? "datapage-v3Utils-utils-cities",
    path: cities3PtWIyRhRgMeta?.path ?? "/datapage/v3Utils/utils/cities",
    meta: cities3PtWIyRhRgMeta || {},
    alias: cities3PtWIyRhRgMeta?.alias || [],
    redirect: cities3PtWIyRhRgMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/datapage/v3Utils/utils/cities.ts").then(m => m.default || m)
  },
  {
    name: hotel_45viewerAEosHo7RceMeta?.name ?? "hotel-viewer",
    path: hotel_45viewerAEosHo7RceMeta?.path ?? "/hotel-viewer",
    meta: hotel_45viewerAEosHo7RceMeta || {},
    alias: hotel_45viewerAEosHo7RceMeta?.alias || [],
    redirect: hotel_45viewerAEosHo7RceMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/hotel-viewer.vue").then(m => m.default || m)
  },
  {
    name: getDatawmsqXkBiv5Meta?.name ?? "instancePicker-getData",
    path: getDatawmsqXkBiv5Meta?.path ?? "/instancePicker/getData",
    meta: getDatawmsqXkBiv5Meta || {},
    alias: getDatawmsqXkBiv5Meta?.alias || [],
    redirect: getDatawmsqXkBiv5Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/instancePicker/getData.ts").then(m => m.default || m)
  },
  {
    name: handleInstance5HPVSVZkfnMeta?.name ?? "instancePicker-handleInstance",
    path: handleInstance5HPVSVZkfnMeta?.path ?? "/instancePicker/handleInstance",
    meta: handleInstance5HPVSVZkfnMeta || {},
    alias: handleInstance5HPVSVZkfnMeta?.alias || [],
    redirect: handleInstance5HPVSVZkfnMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/instancePicker/handleInstance.ts").then(m => m.default || m)
  },
  {
    name: indexiDp8GTjI7jMeta?.name ?? "instancePicker",
    path: indexiDp8GTjI7jMeta?.path ?? "/instancePicker",
    meta: indexiDp8GTjI7jMeta || {},
    alias: indexiDp8GTjI7jMeta?.alias || [],
    redirect: indexiDp8GTjI7jMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/instancePicker/index.vue").then(m => m.default || m)
  },
  {
    name: loginBIQkEeQYpYMeta?.name ?? "login",
    path: loginBIQkEeQYpYMeta?.path ?? "/login",
    meta: loginBIQkEeQYpYMeta || {},
    alias: loginBIQkEeQYpYMeta?.alias || [],
    redirect: loginBIQkEeQYpYMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: afterLogin8VXc9uHXVQMeta?.name ?? "loginUtils-afterLogin",
    path: afterLogin8VXc9uHXVQMeta?.path ?? "/loginUtils/afterLogin",
    meta: afterLogin8VXc9uHXVQMeta || {},
    alias: afterLogin8VXc9uHXVQMeta?.alias || [],
    redirect: afterLogin8VXc9uHXVQMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/loginUtils/afterLogin.ts").then(m => m.default || m)
  },
  {
    name: getAuthorizationsBvLwA0T6qWMeta?.name ?? "loginUtils-afterLoginUtils-getAuthorizations",
    path: getAuthorizationsBvLwA0T6qWMeta?.path ?? "/loginUtils/afterLoginUtils/getAuthorizations",
    meta: getAuthorizationsBvLwA0T6qWMeta || {},
    alias: getAuthorizationsBvLwA0T6qWMeta?.alias || [],
    redirect: getAuthorizationsBvLwA0T6qWMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/loginUtils/afterLoginUtils/getAuthorizations.ts").then(m => m.default || m)
  },
  {
    name: sendSMSwSxCT0QuHBMeta?.name ?? "loginUtils-sendSMS",
    path: sendSMSwSxCT0QuHBMeta?.path ?? "/loginUtils/sendSMS",
    meta: sendSMSwSxCT0QuHBMeta || {},
    alias: sendSMSwSxCT0QuHBMeta?.alias || [],
    redirect: sendSMSwSxCT0QuHBMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/loginUtils/sendSMS.ts").then(m => m.default || m)
  },
  {
    name: SMSLoginGb5uNylBBGMeta?.name ?? "loginUtils-SMSLogin",
    path: SMSLoginGb5uNylBBGMeta?.path ?? "/loginUtils/SMSLogin",
    meta: SMSLoginGb5uNylBBGMeta || {},
    alias: SMSLoginGb5uNylBBGMeta?.alias || [],
    redirect: SMSLoginGb5uNylBBGMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/loginUtils/SMSLogin.ts").then(m => m.default || m)
  },
  {
    name: getAdFiles436Ogwp2vVMeta?.name ?? "project-list-getAdFiles",
    path: getAdFiles436Ogwp2vVMeta?.path ?? "/project-list/getAdFiles",
    meta: getAdFiles436Ogwp2vVMeta || {},
    alias: getAdFiles436Ogwp2vVMeta?.alias || [],
    redirect: getAdFiles436Ogwp2vVMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/project-list/getAdFiles.ts").then(m => m.default || m)
  },
  {
    name: indexByoDZn8mCBMeta?.name ?? "project-list",
    path: indexByoDZn8mCBMeta?.path ?? "/project-list",
    meta: indexByoDZn8mCBMeta || {},
    alias: indexByoDZn8mCBMeta?.alias || [],
    redirect: indexByoDZn8mCBMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/project-list/index.vue").then(m => m.default || m)
  },
  {
    name: report_45viewerdim7pBUmn1Meta?.name ?? "report-viewer",
    path: report_45viewerdim7pBUmn1Meta?.path ?? "/report-viewer",
    meta: report_45viewerdim7pBUmn1Meta || {},
    alias: report_45viewerdim7pBUmn1Meta?.alias || [],
    redirect: report_45viewerdim7pBUmn1Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/report-viewer.vue").then(m => m.default || m)
  },
  {
    name: brand5ovYSb9TsmMeta?.name ?? "section-brand",
    path: brand5ovYSb9TsmMeta?.path ?? "/section/brand",
    meta: brand5ovYSb9TsmMeta || {},
    alias: brand5ovYSb9TsmMeta?.alias || [],
    redirect: brand5ovYSb9TsmMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/brand.vue").then(m => m.default || m)
  },
  {
    name: citykbQDeQQGyGMeta?.name ?? "section-city",
    path: citykbQDeQQGyGMeta?.path ?? "/section/city",
    meta: citykbQDeQQGyGMeta || {},
    alias: citykbQDeQQGyGMeta?.alias || [],
    redirect: citykbQDeQQGyGMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/city.vue").then(m => m.default || m)
  },
  {
    name: cityWallDatasu1QiUAi8UMeta?.name ?? "section-cityWallData",
    path: cityWallDatasu1QiUAi8UMeta?.path ?? "/section/cityWallData",
    meta: cityWallDatasu1QiUAi8UMeta || {},
    alias: cityWallDatasu1QiUAi8UMeta?.alias || [],
    redirect: cityWallDatasu1QiUAi8UMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/cityWallData.ts").then(m => m.default || m)
  },
  {
    name: groupuKY6AEWbO7Meta?.name ?? "section-group",
    path: groupuKY6AEWbO7Meta?.path ?? "/section/group",
    meta: groupuKY6AEWbO7Meta || {},
    alias: groupuKY6AEWbO7Meta?.alias || [],
    redirect: groupuKY6AEWbO7Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/group.vue").then(m => m.default || m)
  },
  {
    name: hotelPY2LgmpwiPMeta?.name ?? "section-hotel",
    path: hotelPY2LgmpwiPMeta?.path ?? "/section/hotel",
    meta: hotelPY2LgmpwiPMeta || {},
    alias: hotelPY2LgmpwiPMeta?.alias || [],
    redirect: hotelPY2LgmpwiPMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/hotel.vue").then(m => m.default || m)
  },
  {
    name: hotelWallDataLe4IiPvMS1Meta?.name ?? "section-hotelWallData",
    path: hotelWallDataLe4IiPvMS1Meta?.path ?? "/section/hotelWallData",
    meta: hotelWallDataLe4IiPvMS1Meta || {},
    alias: hotelWallDataLe4IiPvMS1Meta?.alias || [],
    redirect: hotelWallDataLe4IiPvMS1Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/hotelWallData.ts").then(m => m.default || m)
  },
  {
    name: managementGroupWallDataPSQZzjNxjDMeta?.name ?? "section-managementGroupWallData",
    path: managementGroupWallDataPSQZzjNxjDMeta?.path ?? "/section/managementGroupWallData",
    meta: managementGroupWallDataPSQZzjNxjDMeta || {},
    alias: managementGroupWallDataPSQZzjNxjDMeta?.alias || [],
    redirect: managementGroupWallDataPSQZzjNxjDMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/section/managementGroupWallData.ts").then(m => m.default || m)
  },
  {
    name: _91section_93Xk0KZcqwxdMeta?.name ?? "startPage-section",
    path: _91section_93Xk0KZcqwxdMeta?.path ?? "/startPage/:section()",
    meta: _91section_93Xk0KZcqwxdMeta || {},
    alias: _91section_93Xk0KZcqwxdMeta?.alias || [],
    redirect: _91section_93Xk0KZcqwxdMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/[section].vue").then(m => m.default || m)
  },
  {
    name: checkAuthDRQeRvqMdNMeta?.name ?? "startPage-checkAuth",
    path: checkAuthDRQeRvqMdNMeta?.path ?? "/startPage/checkAuth",
    meta: checkAuthDRQeRvqMdNMeta || {},
    alias: checkAuthDRQeRvqMdNMeta?.alias || [],
    redirect: checkAuthDRQeRvqMdNMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/checkAuth.ts").then(m => m.default || m)
  },
  {
    name: onClickContentNodey53Zimr6HMMeta?.name ?? "startPage-onClickContentNode",
    path: onClickContentNodey53Zimr6HMMeta?.path ?? "/startPage/onClickContentNode",
    meta: onClickContentNodey53Zimr6HMMeta || {},
    alias: onClickContentNodey53Zimr6HMMeta?.alias || [],
    redirect: onClickContentNodey53Zimr6HMMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/onClickContentNode.ts").then(m => m.default || m)
  },
  {
    name: index2vMapyHD3XMeta?.name ?? "startPage-selectOptions",
    path: index2vMapyHD3XMeta?.path ?? "/startPage/selectOptions",
    meta: index2vMapyHD3XMeta || {},
    alias: index2vMapyHD3XMeta?.alias || [],
    redirect: index2vMapyHD3XMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/selectOptions/index.ts").then(m => m.default || m)
  },
  {
    name: asset9XeKkll9hgMeta?.name ?? "startPage-startPageData-asset",
    path: asset9XeKkll9hgMeta?.path ?? "/startPage/startPageData/asset",
    meta: asset9XeKkll9hgMeta || {},
    alias: asset9XeKkll9hgMeta?.alias || [],
    redirect: asset9XeKkll9hgMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/asset.ts").then(m => m.default || m)
  },
  {
    name: assetValuer3kSHZJDxmMeta?.name ?? "startPage-startPageData-assetValue",
    path: assetValuer3kSHZJDxmMeta?.path ?? "/startPage/startPageData/assetValue",
    meta: assetValuer3kSHZJDxmMeta || {},
    alias: assetValuer3kSHZJDxmMeta?.alias || [],
    redirect: assetValuer3kSHZJDxmMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/assetValue.ts").then(m => m.default || m)
  },
  {
    name: brandn1JVwuaAoUMeta?.name ?? "startPage-startPageData-brand",
    path: brandn1JVwuaAoUMeta?.path ?? "/startPage/startPageData/brand",
    meta: brandn1JVwuaAoUMeta || {},
    alias: brandn1JVwuaAoUMeta?.alias || [],
    redirect: brandn1JVwuaAoUMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/brand.ts").then(m => m.default || m)
  },
  {
    name: brandNegotiateSJ0oSiIZ8CMeta?.name ?? "startPage-startPageData-brandNegotiate",
    path: brandNegotiateSJ0oSiIZ8CMeta?.path ?? "/startPage/startPageData/brandNegotiate",
    meta: brandNegotiateSJ0oSiIZ8CMeta || {},
    alias: brandNegotiateSJ0oSiIZ8CMeta?.alias || [],
    redirect: brandNegotiateSJ0oSiIZ8CMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/brandNegotiate.ts").then(m => m.default || m)
  },
  {
    name: budgetcQgbNH5sJiMeta?.name ?? "startPage-startPageData-budget",
    path: budgetcQgbNH5sJiMeta?.path ?? "/startPage/startPageData/budget",
    meta: budgetcQgbNH5sJiMeta || {},
    alias: budgetcQgbNH5sJiMeta?.alias || [],
    redirect: budgetcQgbNH5sJiMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/budget.ts").then(m => m.default || m)
  },
  {
    name: buildSnvP5i46HzMeta?.name ?? "startPage-startPageData-build",
    path: buildSnvP5i46HzMeta?.path ?? "/startPage/startPageData/build",
    meta: buildSnvP5i46HzMeta || {},
    alias: buildSnvP5i46HzMeta?.alias || [],
    redirect: buildSnvP5i46HzMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/build.ts").then(m => m.default || m)
  },
  {
    name: cityYCBzozgrzBMeta?.name ?? "startPage-startPageData-city",
    path: cityYCBzozgrzBMeta?.path ?? "/startPage/startPageData/city",
    meta: cityYCBzozgrzBMeta || {},
    alias: cityYCBzozgrzBMeta?.alias || [],
    redirect: cityYCBzozgrzBMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/city.ts").then(m => m.default || m)
  },
  {
    name: cityCentralActivityZonepSla2S6fbKMeta?.name ?? "startPage-startPageData-cityCentralActivityZone",
    path: cityCentralActivityZonepSla2S6fbKMeta?.path ?? "/startPage/startPageData/cityCentralActivityZone",
    meta: cityCentralActivityZonepSla2S6fbKMeta || {},
    alias: cityCentralActivityZonepSla2S6fbKMeta?.alias || [],
    redirect: cityCentralActivityZonepSla2S6fbKMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/cityCentralActivityZone.ts").then(m => m.default || m)
  },
  {
    name: cityStrategyoF4RCMMwQlMeta?.name ?? "startPage-startPageData-cityStrategy",
    path: cityStrategyoF4RCMMwQlMeta?.path ?? "/startPage/startPageData/cityStrategy",
    meta: cityStrategyoF4RCMMwQlMeta || {},
    alias: cityStrategyoF4RCMMwQlMeta?.alias || [],
    redirect: cityStrategyoF4RCMMwQlMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/cityStrategy.ts").then(m => m.default || m)
  },
  {
    name: designTx0Kh53qLNMeta?.name ?? "startPage-startPageData-design",
    path: designTx0Kh53qLNMeta?.path ?? "/startPage/startPageData/design",
    meta: designTx0Kh53qLNMeta || {},
    alias: designTx0Kh53qLNMeta?.alias || [],
    redirect: designTx0Kh53qLNMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/design.ts").then(m => m.default || m)
  },
  {
    name: empowermentUFkEn5QyZvMeta?.name ?? "startPage-startPageData-empowerment",
    path: empowermentUFkEn5QyZvMeta?.path ?? "/startPage/startPageData/empowerment",
    meta: empowermentUFkEn5QyZvMeta || {},
    alias: empowermentUFkEn5QyZvMeta?.alias || [],
    redirect: empowermentUFkEn5QyZvMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/empowerment.ts").then(m => m.default || m)
  },
  {
    name: evaluationProspect85DASyX29KMeta?.name ?? "startPage-startPageData-evaluationProspect",
    path: evaluationProspect85DASyX29KMeta?.path ?? "/startPage/startPageData/evaluationProspect",
    meta: evaluationProspect85DASyX29KMeta || {},
    alias: evaluationProspect85DASyX29KMeta?.alias || [],
    redirect: evaluationProspect85DASyX29KMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/evaluationProspect.ts").then(m => m.default || m)
  },
  {
    name: feasibilitycbSUUr76oPMeta?.name ?? "startPage-startPageData-feasibility",
    path: feasibilitycbSUUr76oPMeta?.path ?? "/startPage/startPageData/feasibility",
    meta: feasibilitycbSUUr76oPMeta || {},
    alias: feasibilitycbSUUr76oPMeta?.alias || [],
    redirect: feasibilitycbSUUr76oPMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/feasibility.ts").then(m => m.default || m)
  },
  {
    name: groupQDmjMNuotLMeta?.name ?? "startPage-startPageData-group",
    path: groupQDmjMNuotLMeta?.path ?? "/startPage/startPageData/group",
    meta: groupQDmjMNuotLMeta || {},
    alias: groupQDmjMNuotLMeta?.alias || [],
    redirect: groupQDmjMNuotLMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/group.ts").then(m => m.default || m)
  },
  {
    name: groupStrategypxYFIKDhhcMeta?.name ?? "startPage-startPageData-groupStrategy",
    path: groupStrategypxYFIKDhhcMeta?.path ?? "/startPage/startPageData/groupStrategy",
    meta: groupStrategypxYFIKDhhcMeta || {},
    alias: groupStrategypxYFIKDhhcMeta?.alias || [],
    redirect: groupStrategypxYFIKDhhcMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/groupStrategy.ts").then(m => m.default || m)
  },
  {
    name: happyIndustryXyRaGW3vnvMeta?.name ?? "startPage-startPageData-happyIndustry",
    path: happyIndustryXyRaGW3vnvMeta?.path ?? "/startPage/startPageData/happyIndustry",
    meta: happyIndustryXyRaGW3vnvMeta || {},
    alias: happyIndustryXyRaGW3vnvMeta?.alias || [],
    redirect: happyIndustryXyRaGW3vnvMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/happyIndustry.ts").then(m => m.default || m)
  },
  {
    name: homeGroupC5mQyDCakfMeta?.name ?? "startPage-startPageData-homeGroup",
    path: homeGroupC5mQyDCakfMeta?.path ?? "/startPage/startPageData/homeGroup",
    meta: homeGroupC5mQyDCakfMeta || {},
    alias: homeGroupC5mQyDCakfMeta?.alias || [],
    redirect: homeGroupC5mQyDCakfMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homeGroup.ts").then(m => m.default || m)
  },
  {
    name: homeHotelc8j1qu58qnMeta?.name ?? "startPage-startPageData-homeHotel",
    path: homeHotelc8j1qu58qnMeta?.path ?? "/startPage/startPageData/homeHotel",
    meta: homeHotelc8j1qu58qnMeta || {},
    alias: homeHotelc8j1qu58qnMeta?.alias || [],
    redirect: homeHotelc8j1qu58qnMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homeHotel.ts").then(m => m.default || m)
  },
  {
    name: homeHuamei9hiivY7sNqMeta?.name ?? "startPage-startPageData-homeHuamei",
    path: homeHuamei9hiivY7sNqMeta?.path ?? "/startPage/startPageData/homeHuamei",
    meta: homeHuamei9hiivY7sNqMeta || {},
    alias: homeHuamei9hiivY7sNqMeta?.alias || [],
    redirect: homeHuamei9hiivY7sNqMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homeHuamei.ts").then(m => m.default || m)
  },
  {
    name: homeOwnerYedOLACFytMeta?.name ?? "startPage-startPageData-homeOwner",
    path: homeOwnerYedOLACFytMeta?.path ?? "/startPage/startPageData/homeOwner",
    meta: homeOwnerYedOLACFytMeta || {},
    alias: homeOwnerYedOLACFytMeta?.alias || [],
    redirect: homeOwnerYedOLACFytMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homeOwner.ts").then(m => m.default || m)
  },
  {
    name: homePartnersfP39vaPCclMeta?.name ?? "startPage-startPageData-homePartners",
    path: homePartnersfP39vaPCclMeta?.path ?? "/startPage/startPageData/homePartners",
    meta: homePartnersfP39vaPCclMeta || {},
    alias: homePartnersfP39vaPCclMeta?.alias || [],
    redirect: homePartnersfP39vaPCclMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/homePartners.ts").then(m => m.default || m)
  },
  {
    name: hotelOydZNjLB6RMeta?.name ?? "startPage-startPageData-hotel",
    path: hotelOydZNjLB6RMeta?.path ?? "/startPage/startPageData/hotel",
    meta: hotelOydZNjLB6RMeta || {},
    alias: hotelOydZNjLB6RMeta?.alias || [],
    redirect: hotelOydZNjLB6RMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/hotel.ts").then(m => m.default || m)
  },
  {
    name: hotelGroupTourismDestination5fFqVs2ZJ2Meta?.name ?? "startPage-startPageData-hotelGroupTourismDestination",
    path: hotelGroupTourismDestination5fFqVs2ZJ2Meta?.path ?? "/startPage/startPageData/hotelGroupTourismDestination",
    meta: hotelGroupTourismDestination5fFqVs2ZJ2Meta || {},
    alias: hotelGroupTourismDestination5fFqVs2ZJ2Meta?.alias || [],
    redirect: hotelGroupTourismDestination5fFqVs2ZJ2Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/hotelGroupTourismDestination.ts").then(m => m.default || m)
  },
  {
    name: humanResourcezxyzOQa9f3Meta?.name ?? "startPage-startPageData-humanResource",
    path: humanResourcezxyzOQa9f3Meta?.path ?? "/startPage/startPageData/humanResource",
    meta: humanResourcezxyzOQa9f3Meta || {},
    alias: humanResourcezxyzOQa9f3Meta?.alias || [],
    redirect: humanResourcezxyzOQa9f3Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/humanResource.ts").then(m => m.default || m)
  },
  {
    name: indexcS2hq17QwAMeta?.name ?? "startPage-startPageData",
    path: indexcS2hq17QwAMeta?.path ?? "/startPage/startPageData",
    meta: indexcS2hq17QwAMeta || {},
    alias: indexcS2hq17QwAMeta?.alias || [],
    redirect: indexcS2hq17QwAMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/index.ts").then(m => m.default || m)
  },
  {
    name: industryWatchvxCA7i8Q20Meta?.name ?? "startPage-startPageData-industryWatch",
    path: industryWatchvxCA7i8Q20Meta?.path ?? "/startPage/startPageData/industryWatch",
    meta: industryWatchvxCA7i8Q20Meta || {},
    alias: industryWatchvxCA7i8Q20Meta?.alias || [],
    redirect: industryWatchvxCA7i8Q20Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/industryWatch.ts").then(m => m.default || m)
  },
  {
    name: mediationxOleFXU5OfMeta?.name ?? "startPage-startPageData-mediation",
    path: mediationxOleFXU5OfMeta?.path ?? "/startPage/startPageData/mediation",
    meta: mediationxOleFXU5OfMeta || {},
    alias: mediationxOleFXU5OfMeta?.alias || [],
    redirect: mediationxOleFXU5OfMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/mediation.ts").then(m => m.default || m)
  },
  {
    name: nationalHotelXXpWeUDTvKMeta?.name ?? "startPage-startPageData-nationalHotel",
    path: nationalHotelXXpWeUDTvKMeta?.path ?? "/startPage/startPageData/nationalHotel",
    meta: nationalHotelXXpWeUDTvKMeta || {},
    alias: nationalHotelXXpWeUDTvKMeta?.alias || [],
    redirect: nationalHotelXXpWeUDTvKMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/nationalHotel.ts").then(m => m.default || m)
  },
  {
    name: openingyt85EyDQTJMeta?.name ?? "startPage-startPageData-opening",
    path: openingyt85EyDQTJMeta?.path ?? "/startPage/startPageData/opening",
    meta: openingyt85EyDQTJMeta || {},
    alias: openingyt85EyDQTJMeta?.alias || [],
    redirect: openingyt85EyDQTJMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/opening.ts").then(m => m.default || m)
  },
  {
    name: operationMfpMBoEZlqMeta?.name ?? "startPage-startPageData-operation",
    path: operationMfpMBoEZlqMeta?.path ?? "/startPage/startPageData/operation",
    meta: operationMfpMBoEZlqMeta || {},
    alias: operationMfpMBoEZlqMeta?.alias || [],
    redirect: operationMfpMBoEZlqMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/operation.ts").then(m => m.default || m)
  },
  {
    name: owner8RbSL6bDKHMeta?.name ?? "startPage-startPageData-owner",
    path: owner8RbSL6bDKHMeta?.path ?? "/startPage/startPageData/owner",
    meta: owner8RbSL6bDKHMeta || {},
    alias: owner8RbSL6bDKHMeta?.alias || [],
    redirect: owner8RbSL6bDKHMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/owner.ts").then(m => m.default || m)
  },
  {
    name: performanceIjZkAOBkfuMeta?.name ?? "startPage-startPageData-performance",
    path: performanceIjZkAOBkfuMeta?.path ?? "/startPage/startPageData/performance",
    meta: performanceIjZkAOBkfuMeta || {},
    alias: performanceIjZkAOBkfuMeta?.alias || [],
    redirect: performanceIjZkAOBkfuMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/performance.ts").then(m => m.default || m)
  },
  {
    name: productBrandStrategyslo2sUsOULMeta?.name ?? "startPage-startPageData-productBrandStrategy",
    path: productBrandStrategyslo2sUsOULMeta?.path ?? "/startPage/startPageData/productBrandStrategy",
    meta: productBrandStrategyslo2sUsOULMeta || {},
    alias: productBrandStrategyslo2sUsOULMeta?.alias || [],
    redirect: productBrandStrategyslo2sUsOULMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/productBrandStrategy.ts").then(m => m.default || m)
  },
  {
    name: purchasePWmYsFl9QSMeta?.name ?? "startPage-startPageData-purchase",
    path: purchasePWmYsFl9QSMeta?.path ?? "/startPage/startPageData/purchase",
    meta: purchasePWmYsFl9QSMeta || {},
    alias: purchasePWmYsFl9QSMeta?.alias || [],
    redirect: purchasePWmYsFl9QSMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/purchase.ts").then(m => m.default || m)
  },
  {
    name: resourcesi97bYODWlgMeta?.name ?? "startPage-startPageData-resources",
    path: resourcesi97bYODWlgMeta?.path ?? "/startPage/startPageData/resources",
    meta: resourcesi97bYODWlgMeta || {},
    alias: resourcesi97bYODWlgMeta?.alias || [],
    redirect: resourcesi97bYODWlgMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/resources.ts").then(m => m.default || m)
  },
  {
    name: savingsqiP3brBElwMeta?.name ?? "startPage-startPageData-savings",
    path: savingsqiP3brBElwMeta?.path ?? "/startPage/startPageData/savings",
    meta: savingsqiP3brBElwMeta || {},
    alias: savingsqiP3brBElwMeta?.alias || [],
    redirect: savingsqiP3brBElwMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/savings.ts").then(m => m.default || m)
  },
  {
    name: specialTownloI44D33emMeta?.name ?? "startPage-startPageData-specialTown",
    path: specialTownloI44D33emMeta?.path ?? "/startPage/startPageData/specialTown",
    meta: specialTownloI44D33emMeta || {},
    alias: specialTownloI44D33emMeta?.alias || [],
    redirect: specialTownloI44D33emMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/specialTown.ts").then(m => m.default || m)
  },
  {
    name: utilsWxXtPbdPrkMeta?.name ?? "startPage-startPageData-utils",
    path: utilsWxXtPbdPrkMeta?.path ?? "/startPage/startPageData/utils",
    meta: utilsWxXtPbdPrkMeta || {},
    alias: utilsWxXtPbdPrkMeta?.alias || [],
    redirect: utilsWxXtPbdPrkMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/startPage/startPageData/utils.ts").then(m => m.default || m)
  },
  {
    name: _91_filePath_937Gx8uAUsvbMeta?.name ?? "v3-_filePath",
    path: _91_filePath_937Gx8uAUsvbMeta?.path ?? "/v3/:_filePath()",
    meta: _91_filePath_937Gx8uAUsvbMeta || {},
    alias: _91_filePath_937Gx8uAUsvbMeta?.alias || [],
    redirect: _91_filePath_937Gx8uAUsvbMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/[_filePath].vue").then(m => m.default || m)
  },
  {
    name: displayProjectslMdifUvlPSMeta?.name ?? "v3-v3Utils-displayProjects",
    path: displayProjectslMdifUvlPSMeta?.path ?? "/v3/v3Utils/displayProjects",
    meta: displayProjectslMdifUvlPSMeta || {},
    alias: displayProjectslMdifUvlPSMeta?.alias || [],
    redirect: displayProjectslMdifUvlPSMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/displayProjects.ts").then(m => m.default || m)
  },
  {
    name: getAdFilesIRK0XrYorDMeta?.name ?? "v3-v3Utils-getAdFiles",
    path: getAdFilesIRK0XrYorDMeta?.path ?? "/v3/v3Utils/getAdFiles",
    meta: getAdFilesIRK0XrYorDMeta || {},
    alias: getAdFilesIRK0XrYorDMeta?.alias || [],
    redirect: getAdFilesIRK0XrYorDMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/getAdFiles.ts").then(m => m.default || m)
  },
  {
    name: getCategory1OptionsdeyExqkpl5Meta?.name ?? "v3-v3Utils-getCategory1Options",
    path: getCategory1OptionsdeyExqkpl5Meta?.path ?? "/v3/v3Utils/getCategory1Options",
    meta: getCategory1OptionsdeyExqkpl5Meta || {},
    alias: getCategory1OptionsdeyExqkpl5Meta?.alias || [],
    redirect: getCategory1OptionsdeyExqkpl5Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/getCategory1Options.ts").then(m => m.default || m)
  },
  {
    name: getPageContentiY5kyafR1bMeta?.name ?? "v3-v3Utils-getPageContent",
    path: getPageContentiY5kyafR1bMeta?.path ?? "/v3/v3Utils/getPageContent",
    meta: getPageContentiY5kyafR1bMeta || {},
    alias: getPageContentiY5kyafR1bMeta?.alias || [],
    redirect: getPageContentiY5kyafR1bMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/getPageContent.ts").then(m => m.default || m)
  },
  {
    name: getPageContentOverridei5yz5N3DblMeta?.name ?? "v3-v3Utils-getPageContentOverride",
    path: getPageContentOverridei5yz5N3DblMeta?.path ?? "/v3/v3Utils/getPageContentOverride",
    meta: getPageContentOverridei5yz5N3DblMeta || {},
    alias: getPageContentOverridei5yz5N3DblMeta?.alias || [],
    redirect: getPageContentOverridei5yz5N3DblMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/getPageContentOverride.ts").then(m => m.default || m)
  },
  {
    name: huameiAnnualspwv2HSaQpGMeta?.name ?? "v3-v3Utils-huameiAnnuals",
    path: huameiAnnualspwv2HSaQpGMeta?.path ?? "/v3/v3Utils/huameiAnnuals",
    meta: huameiAnnualspwv2HSaQpGMeta || {},
    alias: huameiAnnualspwv2HSaQpGMeta?.alias || [],
    redirect: huameiAnnualspwv2HSaQpGMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/huameiAnnuals.ts").then(m => m.default || m)
  },
  {
    name: processLeftMenu2nzk4BAGiuMeta?.name ?? "v3-v3Utils-processLeftMenu",
    path: processLeftMenu2nzk4BAGiuMeta?.path ?? "/v3/v3Utils/processLeftMenu",
    meta: processLeftMenu2nzk4BAGiuMeta || {},
    alias: processLeftMenu2nzk4BAGiuMeta?.alias || [],
    redirect: processLeftMenu2nzk4BAGiuMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/processLeftMenu.ts").then(m => m.default || m)
  },
  {
    name: brandsY3NYGoxlAzMeta?.name ?? "v3-v3Utils-utils-brands",
    path: brandsY3NYGoxlAzMeta?.path ?? "/v3/v3Utils/utils/brands",
    meta: brandsY3NYGoxlAzMeta || {},
    alias: brandsY3NYGoxlAzMeta?.alias || [],
    redirect: brandsY3NYGoxlAzMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/utils/brands.ts").then(m => m.default || m)
  },
  {
    name: citiesvAE3DBHTygMeta?.name ?? "v3-v3Utils-utils-cities",
    path: citiesvAE3DBHTygMeta?.path ?? "/v3/v3Utils/utils/cities",
    meta: citiesvAE3DBHTygMeta || {},
    alias: citiesvAE3DBHTygMeta?.alias || [],
    redirect: citiesvAE3DBHTygMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/hm-cloud/frontend/pages/v3/v3Utils/utils/cities.ts").then(m => m.default || m)
  }
]