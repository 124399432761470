// Remove Node.js crypto import
// import crypto from "crypto";

const fileSignatureKey = "secret"

// Helper function to convert ArrayBuffer to hex string
function bufferToHex(buffer: ArrayBuffer): string {
    return Array.from(new Uint8Array(buffer))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
}

// Helper function to convert string to ArrayBuffer for crypto API
function stringToBuffer(str: string): Uint8Array {
    return new TextEncoder().encode(str);
}

export function getExtension(fileName: string): string {
    const parts = fileName.split(".");
    if (parts.length < 2) {
      return ""; // No extension or invalid input
    }
    return `.${parts[parts.length - 1]}`;
}

const getFileNameBase = (fileName: string): string => {
    const parts = fileName.split(".");
    if (parts.length < 2) {
      return fileName; // No extension or invalid input
    }
    return parts.slice(0, -1).join(".");
}

const signFileNameCache = {} as any
export const signFileName = async (fileName: string): Promise<string> => {
    if (signFileNameCache[fileName]) {
        return signFileNameCache[fileName];
    }

    const fileNameExt = getExtension(fileName);
    const fileNameBase = getFileNameBase(fileName); // Remove extension

    try {
        // Import the key for HMAC
        const keyData = stringToBuffer(fileSignatureKey);
        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            keyData,
            { name: 'HMAC', hash: 'SHA-256' },
            false,
            ['sign']
        );

        // Sign the data
        const data = stringToBuffer(fileNameBase);
        const signature = await window.crypto.subtle.sign(
            'HMAC',
            cryptoKey,
            data
        );

        // Convert signature to hex
        const signatureHex = bufferToHex(signature);
        
        signFileNameCache[fileName] = `${fileNameBase}{${signatureHex}}${fileNameExt}`;
        return signFileNameCache[fileName];
    } catch (error) {
        console.error('Error signing filename:', error);
        return fileName; // Fallback to original filename
    }
}

const signFilePathCache = {} as any
export const signFilePath = async (filePath: string, hashFull?: boolean): Promise<string> => {
    if(signFilePathCache[filePath]){
        return signFilePathCache[filePath];
    }
    const parts = filePath.split("/").filter(Boolean);
    const lastPartIndex = parts.length - 1;
    
    // Handle the last part (filename) differently
    const signedFileName = await signFileName(parts[lastPartIndex]);
    
    // Replace the last part with the signed filename
    parts[lastPartIndex] = signedFileName;
    
    const res = parts.join("/");
    signFilePathCache[filePath] = res;
    return res;
}

export const getBackwardCompatibleOSSPath = async (filePath: string): Promise<string> => {
    const signedFilePath = await signFilePath(filePath);
    return signedFilePath;
}
