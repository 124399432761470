import { formatURIComponent } from './utils'

export default (instance:string) => {

    const makeUrl = (path:string) => {
        return `/browser2?file_path=${formatURIComponent(path, instance)}&menu_key=hotel&instance=${instance}`
    }
        
    return [{
        pageLayout: 'tabs_small',
        sectionCategory: "hotel",
        slogan: '',
        pageTitle: '评估与展望 - '+instance,
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '趋势分析',
                img: '/icons/趋势分析.svg',
                background: 'black',
                url: makeUrl('/内容文件/评估展望/酒店/趋势分析/%s.pdf'),
                path: "评估展望/酒店/趋势分析"
            },
            {
                name: '对比分析',
                tabStyle:"color:red",
                img: '/icons/对比分析.svg',
                background: 'black',    
                url: makeUrl('/内容文件/评估展望/酒店/对比分析/%s.pdf'),
                path: "评估展望/酒店/对比分析"
            }
        ]
    }]
}

