
import { checkNodeAvailable } from '~/controllers/checkAvailable'
import { modal } from '@tian-libs/modal'

export const sectionTranslate = {
    "hotel": "酒店",
    "brand": "品牌",
    "city": "城市",
    "group": "集团",
    "build": "建设",
    "asset": "资管",
    "industry": "行业",
    "evaluationProspect": "评估展望",
    "empowerment": "四库赋能",
    "brandNegotiate": "品牌引进",
    "budget": "预算审核",
    "design": "设计管理",
    "feasibility": "定位可研",
    "humanResource": "人资优化",
    "opening": "开业筹备",
    "operation": "经营提升",
    "owner": "业主代表",
    "performance": "业绩评估",
    "purchase": "采购顾问",
    "assetValue": "资产交易及证券化",
    "mediation": "无法无天",
    "cityStrategy": "城市战略（十五五）",
    "groupStrategy": "集团战略（十五五）",
    "productBrandStrategy": "产品及品牌战略",
    "hotelGroupTourismDestination": "酒店群旅游度假目的地",
    "cityCentralActivityZone": "城市中央活力区（CAZ）",
    "specialTown": "特色小镇",
    "happyIndustry": "幸福产业",

    "resources": "优选资源",

    "homeGroup":"homeGroup",
    "homeHotel":"homeHotel",
    "homeOwner":"homeOwner",
    "homeHuamei":"homeHuamei",
    "homePartners":"homePartners",
}

export const onClickContentNode = (node, instance, section) => {

    if(!node.name) {
        return
    }

    const whiteListSections = [
        "评估展望",
        "四库赋能",
    ]

    const _isAvailable = checkNodeAvailable(node, instance)
        || whiteListSections.includes(sectionTranslate[section])
    if(!_isAvailable){
        if(instance){
            modal.alert(`您没有权限访问 ${instance} - ${node.name}，请联系华小美购买相关内容`)
        }else{
            modal.alert(`您没有权限访问 ${sectionTranslate[section]} - ${node.name}，请联系华小美购买相关内容`)
        }
        return
    }
    // console.log("onClickContentNode", node, instance, section, _isAvailable)
    // return
    if(node.url){
        window.location.href = node.url
    }
    return 

}
