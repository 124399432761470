import asset from './asset'
import assetValue from './assetValue'
import brand from './brand'
import brandNegotiate from './brandNegotiate'
import budget from './budget'
import build from './build'
import city from './city'
import design from './design'
import feasibility from './feasibility'
import group from './group'
import homeGroup from './homeGroup'
import homeHuamei from './homeHuamei'
import homePartners from './homePartners'
import homeHotel from './homeHotel'
import homeOwner from './homeOwner'
import humanResource from './humanResource'
import opening from './opening'
import operation from './operation'
import owner from './owner'
import performance from './performance'
import purchase from './purchase'
import hotel from './hotel'
import evaluationProspect from './evaluationProspect'
import empowerment from './empowerment'
import resources from './resources'
import mediation from './mediation'
import cityStrategy from './cityStrategy'
import groupStrategy from './groupStrategy'
import productBrandStrategy from './productBrandStrategy'
import hotelGroupTourismDestination from './hotelGroupTourismDestination'
import specialTown from './specialTown'
import happyIndustry from './happyIndustry'
import cityCentralActivityZone from './cityCentralActivityZone'
import industryWatch from './industryWatch'
import savings from './savings'
import nationalHotel from './nationalHotel'

export default (category:string, instance:string) => {
    if(category == "asset"){
        return asset(instance)
    }
    if(category == "assetValue"){
        return assetValue(instance)
    }
    if(category == "brand"){
        return brand(instance)
    }
    if(category == "brandNegotiate"){
        return brandNegotiate(instance)
    }
    if(category == "budget"){
        return budget(instance)
    }
    if(category == "build"){
        return build(instance)
    }
    if(category == "city"){
        return city(instance)
    }
    if(category == "design"){
        return design(instance)
    }
    if(category == "feasibility"){
        return feasibility(instance)
    }
    if(category == "group"){
        return group(instance)
    }
    if(category == "homeHotel"){
        return homeHotel(instance)
    }
    if(category == "homeGroup"){
        return homeGroup(instance)
    }
    if(category == "homeOwner"){
        return homeOwner(instance)
    }
    if(category == "homeHuamei"){
        return homeHuamei(instance)
    }
    if(category == "homePartners"){
        return homePartners(instance)
    }
    if(category == "hotel"){
        return hotel(instance)
    }
    if(category == "humanResource"){
        return humanResource(instance)
    }
    if(category == "opening"){
        return opening(instance)
    }
    if(category == "operation"){
        return operation(instance)
    }
    if(category == "owner"){
        return owner(instance)
    }
    if(category == "performance"){
        return performance(instance)
    }
    if(category == "purchase"){
        return purchase(instance)
    }
    if(category == "evaluationProspect"){
        return evaluationProspect()
    }
    if(category == "empowerment"){
        return empowerment(instance)
    }
    if(category == "resources"){
        return resources(instance)
    }
    if(category == "mediation"){
        return mediation(instance)
    }
    if(category == "cityStrategy"){
        return cityStrategy(instance)
    }
    if(category == "groupStrategy"){
        return groupStrategy(instance)
    }
    if(category == "productBrandStrategy"){
        return productBrandStrategy(instance)
    }
    if(category == "hotelGroupTourismDestination"){
        return hotelGroupTourismDestination(instance)
    }
    if(category == "specialTown"){
        return specialTown(instance)
    }
    if(category == "happyIndustry"){
        return happyIndustry(instance)
    }
    if(category == "cityCentralActivityZone"){
        return cityCentralActivityZone(instance)
    }
    if(category == "industryWatch"){
        return industryWatch(instance)
    }
    if(category == "savings"){
        return savings(instance)
    }
    if(category == "nationalHotel"){
        return nationalHotel(instance)
    }
}
