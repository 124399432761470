import { formatURIComponent } from "./utils"

export default (instance:string) => {

    const makeUrl = (path:string) => {
        return `/browser2?file_path=${formatURIComponent(path, instance)}&menu_key=owner&instance=${instance}`
    }

    return [{
        pageLayout: 'tabs_small',
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '业主监管',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        description: `在这个充满挑战与机遇的关键时期，酒店业主和管理
公司应当相互⽀持和衷共济。华美顾问秉承解决问题向前看的正能思维，为酒
店业主和管理公司提供基于⾏业⼤数据的客观中⽴的评估及优化服务。`,
        icons: [
            {
                name: '成功案例',
                img: '/icons/成功案例.svg',
                background: 'black',
                url: makeUrl('成功案例'),
                path: "四库赋能-酒店资管/业主监管/成功案例"
            },
            {
                name: '全球案例',
                img: '/icons/案例库.svg',
                background: 'black',
                url: makeUrl('/内容文件/四库赋能-酒店资管/业主监管/全球案例/全球案例（业主监管）.xlsx'),
                path: "四库赋能-酒店资管/业主监管/全球案例"
            },
            {
                name: '预警问题',
                img: '/icons/工具库.svg',
                background: 'black',
                url: makeUrl('/内容文件/四库赋能-酒店资管/业主监管/预警问题/预警问题（业主监管）.xlsx'),
                path: "四库赋能-酒店资管/业主监管/预警问题"
            },
            {
                name: '智能工具',
                img: '/icons/工具库.svg',
                background: 'black',
                url: makeUrl('/内容文件/四库赋能-酒店资管/业主监管/智能工具/智能工具.xlsx'),
                path: "四库赋能-酒店资管/业主监管/智能工具"
            },
            {
                name: '优选资源',
                img: '/icons/工具库.svg',
                background: 'black',
                url: makeUrl('/内容文件/四库赋能-酒店资管/业主监管/优选资源'),
                path: "四库赋能-酒店资管/业主监管/优选资源"
            }
        ]
    }]
}