import { useUserInfoStore } from "~/stores/userInfoStore"

export const formatURIComponent = (template: string, ...params: any) => {
    for (const param of params) {
        template = template.replace('%s', param)
    }
    return encodeURIComponent(template)
}


export const makeRecommends = (key: string, configs?:any) => {
    const recommends = [
        {
            name: '',
            img: '',
            background: 'black',
            url: ``,
            path: ""
        },
        {
            name: '',
            img: '',
            background: 'black',
            url: ``,
            path: ""
        },
        {
            name: '',
            img: '',
            background: 'black',
            url: ``,
            path: ""
        },
    ].map(x=>({...x, hideInMenu: true}))
    const userInfo = useUserInfoStore().info
    if(!userInfo) {
        return recommends
    }
    const userRecommends = userInfo.recommends
    if(!userRecommends) {
        return recommends
    }
    const filterdRecommends = userRecommends.filter(recommend => recommend.content_node_path == key)
    for (const recommend of filterdRecommends) {
        recommends[recommend.order] = {
            name: recommend.data.title,
            img: recommend.data.icon,
            background: configs?.background || 'black',
            url: recommend.data.link,
            path: recommend.content_node_path,
            hideInMenu: true
        }
    }
    return recommends.map(x=>({...x, hideInMenu: true}))
}