import { formatURIComponent } from "./utils"

export default (instance:string) => {

    const makeUrl = (path:string) => {
        return `/browser2?file_path=${formatURIComponent(path, instance)}&menu_key=mediation&instance=${instance}`
    }

    return [{
        pageLayout: 'tabs_small',
        slogan: '',
        pageTitle: '劝和止战',
        pageTitleIcon: '',
        background_img: '',
        description: `在这个充满挑战与机遇的关键时期，酒店业主和管理
公司应当相互⽀持和衷共济。华美顾问秉承解决问题向前看的正能思维，为酒
店业主和管理公司提供基于⾏业⼤数据的客观中⽴的评估及优化服务。`,
        icons: [
            {
                name: '全球案例',
                img: '/icons/案例库.svg',
                background: 'black',
                url: makeUrl('/内容文件/四库赋能-酒店资管/劝和止战/全球案例/全球案例（劝和止战）.xlsx'),
                path: "四库赋能-酒店资管/劝和止战/全球案例"
            },
            {
                name: '无法无天',
                img: '/icons/劝和止战.svg',
                url: makeUrl('/内容文件/四库赋能-酒店资管/劝和止战/【隐藏】全球案例文件/无法无天.pdf'),
                background: 'black',
                path: "四库赋能-酒店资管/劝和止战/无法无天"
            },
            {
                name: '吐槽大会',
                img: '/icons/劝和止战.svg',
                url: makeUrl('/内容文件/四库赋能-酒店资管/劝和止战/【隐藏】全球案例文件/业主代表吐槽大会.pdf'),
                background: 'black',
                path: "四库赋能-酒店资管/劝和止战/吐槽大会"
            },
            {
                name: '业主代表工作指南',
                img: '/icons/劝和止战.svg',
                url: makeUrl('/内容文件/四库赋能-酒店资管/劝和止战/【隐藏】全球案例文件/业主代表工作指南.pdf'),
                background: 'black',
                path: "四库赋能-酒店资管/劝和止战/业主代表工作指南"
            },
            {
                name: '酒店业主代表调查2024',
                img: '/icons/劝和止战.svg',
                url: makeUrl('/内容文件/四库赋能-酒店资管/劝和止战/【隐藏】全球案例文件/酒店业主代表调查2024.pdf'),
                background: 'black',
                path: "四库赋能-酒店资管/劝和止战/酒店业主代表调查2024"
            },
        ]
    }]
}